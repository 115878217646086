import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/postLayout.js";
import Seo from "../../components/seo";
import VideoPlayer from "../../components/post/video";
import BlockWrapper from "../../components/post/BlockWrapper";
import EmbedVideo from "../../components/post/embedvideo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title="Atlas AI - DI | Atharva Patil" mdxType="Seo" />
    <h1>{`A demand intelligence platform to help organizations find every last customer`}</h1>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1200px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "48.666666666666664%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Screenshots of Atlas AI working product",
        "title": "Screenshots of Atlas AI working product",
        "src": "/static/6c56f2422dc5ab2ac0d7281e31cd421d/c1b63/atlas-cover.png",
        "srcSet": ["/static/6c56f2422dc5ab2ac0d7281e31cd421d/5a46d/atlas-cover.png 300w", "/static/6c56f2422dc5ab2ac0d7281e31cd421d/0a47e/atlas-cover.png 600w", "/static/6c56f2422dc5ab2ac0d7281e31cd421d/c1b63/atlas-cover.png 1200w", "/static/6c56f2422dc5ab2ac0d7281e31cd421d/d61c2/atlas-cover.png 1800w", "/static/6c56f2422dc5ab2ac0d7281e31cd421d/efb68/atlas-cover.png 2032w"],
        "sizes": "(max-width: 1200px) 100vw, 1200px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <div style={{
      "maxWidth": "840px",
      "margin": "0 auto"
    }}>
      <h2>{`Overview`}</h2>
      <p>{`Aperture™ demand intelligence is the latest product by `}<a parentName="p" {...{
          "href": "https://www.atlasai.co/"
        }}>{`Atlas AI`}</a>{`. The platform helps customers understand their current business operations holistically by combining their own data with Atlas AI’s proprietary socio-economic & demographic.`}</p>
      <p>{`Our goal is to help customers understand their business better in conjunction with changing market dynamics so they actionable insights to improve, optimize and expand their business. The demand intelligence platform helps customers better operational decisions contextualizing the data using different visualizations such as dashboards, geospatial visualization and dynamic tabular views.`}</p>
      <h2>{`My role`}</h2>
      <p>{`User and technology research, lead designer & prototype`}<b>{`(2020 - Present)`}</b></p>
      <h5>{`Collaborators:`}</h5>
      <p>{`Deven Desai(Product), Jeff Bishop, Bborie Park, Jacob Chapman, Mohammad Yazdani, Austin Lee, etc.(Engineering)`}</p>
      <h2>{`Context`}</h2>
      <p>{`We are iterating on the demand intelligence product with a handful of customers to better understand customer needs broadly across different industries and the guiding indicators that drive their businesses.`}</p>
      <p>{`Although I can’t shared any specific details you can find some of the images we have made public as part of our outreach and marketing. The product itself is evolving so they may not look the same even couple of weeks/months from now. `}<a parentName="p" {...{
          "href": "mailto:atharvaabhaypatil@gmail.com"
        }}>{`Reach out`}</a>{` to me if you have any questions or want to learn more.`}</p>
      <p>{`Or checkout more documentation on our first product `}<a parentName="p" {...{
          "href": "/work/aperture-research"
        }}>{`Aperture™ research`}</a>{`.`}</p>
      <h5><i>{`Last update: 30 Aug 2022`}</i></h5>
      <hr></hr>
      <h2>{`Designs`}</h2>
      <figure>{`
  `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Key region highlights for a data attribute",
            "title": "Key region highlights for a data attribute",
            "src": "/static/b0880be86df55e609f2b887264a7c77d/c1b63/image1.png",
            "srcSet": ["/static/b0880be86df55e609f2b887264a7c77d/5a46d/image1.png 300w", "/static/b0880be86df55e609f2b887264a7c77d/0a47e/image1.png 600w", "/static/b0880be86df55e609f2b887264a7c77d/c1b63/image1.png 1200w", "/static/b0880be86df55e609f2b887264a7c77d/0a013/image1.png 1703w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
  `}<figcaption parentName="figure">{`
    Geospatial visualization with filters applied for narrowing areas of
    interest.
  `}</figcaption>
      </figure>
      <figure>{`
  `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Dashboard to understand the core business",
            "title": "Dashboard to understand the core business",
            "src": "/static/f6cc2d3a4da46b04b212470977b34c42/c1b63/image2.png",
            "srcSet": ["/static/f6cc2d3a4da46b04b212470977b34c42/5a46d/image2.png 300w", "/static/f6cc2d3a4da46b04b212470977b34c42/0a47e/image2.png 600w", "/static/f6cc2d3a4da46b04b212470977b34c42/c1b63/image2.png 1200w", "/static/f6cc2d3a4da46b04b212470977b34c42/0a013/image2.png 1703w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
  `}<figcaption parentName="figure">{`Dashboard to understand the core business.`}</figcaption>
      </figure>
      <figure>{`
  `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Tablular view of the data",
            "title": "Tablular view of the data",
            "src": "/static/b1acbc80a5c45d57b3478e16e83c94d1/c1b63/image3.png",
            "srcSet": ["/static/b1acbc80a5c45d57b3478e16e83c94d1/5a46d/image3.png 300w", "/static/b1acbc80a5c45d57b3478e16e83c94d1/0a47e/image3.png 600w", "/static/b1acbc80a5c45d57b3478e16e83c94d1/c1b63/image3.png 1200w", "/static/b1acbc80a5c45d57b3478e16e83c94d1/0a013/image3.png 1703w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
  `}<figcaption parentName="figure">{`Tablular view of the data.`}</figcaption>
      </figure>
      <hr></hr>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      